import React from "react";
import "./App.css";
import Velvet_Lounge_Logo from "./velvet_lounge_logo.svg";

function App() {
  return (
    <div className='coming-soon'>
      <div className='logo'>
        <img src={Velvet_Lounge_Logo} alt='Velvet lounge Logo' />
      </div>
      <div className='coming-soon-message'>
        <h1>
          Excitement Awaits: <br />
          <span>Velvet Lounge</span> will be Opening Soon! <br />
          Stay tuned for new experiences!
        </h1>
      </div>
    </div>
  );
}

export default App;
